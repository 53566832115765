<template>
  <router-link :to="{name: 'home'}" class="backLink">
    <div class="back">← Zurück</div>
  </router-link>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component()
export default class BackButton extends Vue {

}
</script>
<style scoped lang="scss">
.backLink {
  $height: 50px;
  display: inline;
  min-height: $height;

  .back {
    margin-right: 100%;
    height: $height;
    width: 188px;
    margin-bottom: 25px;
    margin-top: 5px;

    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
  }
}

a {
  text-decoration: none;
}
</style>
